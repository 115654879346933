import axios from 'axios';
import i18next from 'i18next';

export const setFetching = value => dispatch => {
  dispatch({
    type: 'SET_FETCHING',
    payload: value,
  });
};

export const getVeterinarians = () => async dispatch => {
  let response = {
    code: 0,
    msg: '',
  };

  try {
    const res = await axios.get('/jsonapi/entity/veterinarians?_format=json');

    dispatch({
      type: 'GET_VETERINARIANS',
      payload: res.data,
    });
    response.code = 200;
  } catch (err) {
    setFetching(false);
    if (navigator.onLine) {
      response.code = 400;
      response.msg = i18next.t('alerts.errors.veterinarians.couldNotFetch');
    } else {
      response.code = 504;
      response.msg = i18next.t('alerts.errors.offlineDataNotFetched');
    }
  }

  return response;
};

export const addVeterinarian =
  (veterinarian, activeSupplier) => async dispatch => {
    let response = {
      code: 0,
      msg: '',
    };

    const formData = {
      _links: {
        type: {
          href: 'https://www.backend.bpgap.creathing.be/rest/type/veterinarian/veterinarian',
          // href: 'https://www.backend.pigplaza.be/rest/type/veterinarian/veterinarian',
        },
      },
      _embedded: {
        'https://www.backend.bpgap.creathing.be/rest/relation/veterinarian/veterinarian/field_organization':
        // 'https://www.backend.pigplaza.be/rest/relation/veterinarian/veterinarian/field_organization':
          [{ uuid: { value: activeSupplier } }],
      },
      title: { value: veterinarian.title },
      field_address: {
        value: {
          address_line1: veterinarian.address_line1,
          postal_code: veterinarian.postcode,
          locality: veterinarian.locality,
          country_code: veterinarian.country_code,
        },
      },
      field_telephone: { value: veterinarian.telephone },
      field_email: { value: veterinarian.email },
    };

    const config = {
      headers: {
        'Content-Type': 'application/hal+json',
      },
    };

    try {
      const res = await axios.post(
        '/entity/veterinarian?_format=hal_json',
        JSON.stringify(formData),
        config
      );

      const payloadVet = {
        ...veterinarian,
        id: res.data.id[0].value,
        uuid: res.data.uuid[0].value,
      };

      dispatch({
        type: 'ADD_VETERINARIAN',
        payload: payloadVet,
      });
      response.code = 200;
      response.msg = i18next.t('alerts.success.veterinarians.added');
    } catch (err) {
      if (navigator.onLine) {
        response.code = 400;
        response.msg = i18next.t('alerts.errors.veterinarians.couldNotAdd');
      } else {
        response.code = 504;
        response.msg = i18next.t('alerts.warnings.pwaDataSync');
      }
    }

    return response;
  };

export const deleteVeterinarian = id => async dispatch => {
  let response = {
    code: 0,
    msg: '',
  };

  try {
    await axios.delete(`/veterinarian/${id}?_format=json`);

    dispatch({
      type: 'DELETE_VETERINARIAN',
      payload: id,
    });
    response.code = 200;
    response.msg = i18next.t('alerts.success.veterinarians.removed');
  } catch (err) {
    if (navigator.onLine) {
      response.code = 400;
      response.msg = i18next.t('alerts.errors.veterinarians.couldNotRemove');
    } else {
      response.code = 504;
      response.msg = i18next.t('alerts.warnings.pwaDataSync');
    }
  }

  return response;
};

export const updateVeterinarian =
  (veterinarian, activeSupplier) => async dispatch => {
    let response = {
      code: 0,
      msg: '',
    };

    const formData = {
      _links: {
        type: {
          href: 'https://www.backend.bpgap.creathing.be/rest/type/veterinarian/veterinarian',
          // href: 'https://www.backend.pigplaza.be/rest/type/veterinarian/veterinarian',
        },
      },
      // _embedded: {
      //   "https://www.backend.bpgap.creathing.be/rest/relation/veterinarian/veterinarian/field_organization": [{uuid: {value: activeSupplier}}]
      // },
      title: { value: veterinarian.title },
      field_address: {
        value: {
          address_line1: veterinarian.address_line1,
          postal_code: veterinarian.postcode,
          locality: veterinarian.locality,
          country_code: veterinarian.country_code,
        },
      },
      field_telephone: { value: veterinarian.telephone },
      field_email: { value: veterinarian.email },
    };

    const config = {
      headers: {
        'Content-Type': 'application/hal+json',
      },
    };

    try {
      await axios.patch(
        `/veterinarian/${veterinarian.id}?_format=hal_json`,
        JSON.stringify(formData),
        config
      );

      dispatch({
        type: 'UPDATE_VETERINARIAN',
        payload: veterinarian,
      });
      response.code = 200;
      response.msg = i18next.t('alerts.success.veterinarians.edited');
    } catch (err) {
      if (navigator.onLine) {
        response.code = 400;
        response.msg = i18next.t('alerts.errors.veterinarians.couldNotEdit');
      } else {
        response.code = 504;
        response.msg = i18next.t('alerts.warnings.pwaDataSync');
      }
    }

    return response;
  };

export const setCurrentVeterinarian = veterinarian => dispatch => {
  dispatch({
    type: 'SET_CURRENT_VETERINARIAN',
    payload: veterinarian,
  });
};

export const clearCurrentVeterinarian = () => dispatch => {
  dispatch({ type: 'CLEAR_CURRENT_VETERINARIAN' });
};

export const setLoading = value => dispatch => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

export const clearVeterinarianState = () => dispatch => {
  dispatch({
    type: 'CLEAR_STATE',
  });
};
